<template>
  <div class="page-bookshelf common-page">
    <div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack">
      </div>
      <h1 class="page-title">
        BookShelf
      </h1>
      <div class="btn-act"></div>
    </div>
    <div class="page-con">
      <div class="bookshelf-list" v-if="bookList.length">
        <div class="one-book" v-for="item of bookList" :key="item.book_id" @click="jumpToBook(item.book_id)">
          <div class="cover">
            <img loading="lazy" :src="item.avatar" alt="">
          </div>
          <div class="name">{{ item.book_name }}</div>
          <!-- <div class="progress">
            {{ item.progress }}%
          </div> -->
        </div>
      </div>
      <div class="bookshelf-empty fx-vc" v-if="!loading && !bookList.length">
        There are no books in your library. Visit the Discovery to pick up your favorite books.
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetBookShelfList } from '../../api';

export default {
  name: 'BookShelf',
  data() {
    return {
      pageNo:1,
      pageSize:10,
      bookList: [],
      loading: true,
    }
  },
  components: {
  },
  computed: {
  }, 
  methods: {
    pageBack() {
      this.$router.push({
        name: 'HomePage'
      })
    },
    async getBookShelfList() {
      const {code ,data} = await apiGetBookShelfList({
        page: this.pageNo,
        size: this.pageSize
      });
      this.$toast.clear();
      if(code) return;
      this.bookList = data || []
    },
    jumpToBook(id) {
      this.$router.push({
        name: 'BookDetail',
        params: {
          id
        }
      })
    },
  },
  mounted() {
    this.$toast.loading({
      duration: 0
    })
    this.getBookShelfList()
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>